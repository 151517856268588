<template>
  <div class="error-page-holder">
    <div class="error-content-holder">
      <VueIcon
        class="icon"
        :iconName="iconWarn.name"
        :width="iconWarn.width"
        :height="iconWarn.height"
        iconColor="red"
      ></VueIcon>
      <VueText sizeLevel="7" class="content-message"
        >Üzgünüz! <br />
        Sayfa Bulunamamaktadır</VueText
      >
      <div class="button-wrapper">
        <BrandButton @click="handleOnGoToButtonClick" :size="sizeSmall"
          >ANASAYFA'YA DÖN</BrandButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { mapGetters } from 'vuex';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'NotFound',
  components: {
    VueText,
    VueIcon,
    BrandButton,
  },
  computed: {
    iconWarn() {
      return ICON_VARIABLES.warn;
    },
    sizeSmall() {
      return COMPONENT_SIZES.small;
    },
    ...mapGetters('auth', ['isAuthenticated']),
  },
  methods: {
    handleOnGoToButtonClick() {
      const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();
      this.$router.push(
        this.isAuthenticated && !user.isImageRecognitionUser
          ? `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`
          : this.isAuthenticated && user.isImageRecognitionUser
          ? `${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}`
          : `${RoutesRoot.Home.path}`,
      );
    },
  },
};
</script>

<style scoped lang="scss">
.error-page-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  .error-content-holder {
    .icon {
      transform: scale(1.4);
      margin-bottom: palette-space-level('30');
    }

    .content-message {
      margin-bottom: palette-space-level('20');
      line-height: 1.2;
    }
    .button-wrapper {
      max-width: 160px;
      margin: auto;
    }
  }
}
</style>
